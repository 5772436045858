<template>
  <div id='eligibility-tracking-tbl'>
    <b-table sort-icon-left class='table-bordered' table-class='width-auto' striped hover sticky-header='800px'
             :items='data'
             :fields='fields'>
      <template #cell()='data'>
        <div v-if='!table_settings.links.includes(data.field.key)' :title="data.field.class ? data.value : ''">
          <div v-if='table_settings.booleans.includes(data.field.key)'>
            <a
              v-if="data.value && data.item.atlassian_issue"
              :href='`https://${data.item.atlassian_issue}`'>
              Yes
            </a>
            <span v-if='data.value && !data.item.atlassian_issue'>
              Yes
            </span>
            <span v-else>
              No
            </span>
          </div>
          <div v-else>
            <span>
              {{data.value}}
            </span>
          </div>
        </div>
        <b-link
          v-if="data.field.key === 'provider_link'"
          :to='data.value'>
          Link
        </b-link>
        <b-link
          v-if="data.field.key === 'config_link'"
          :to='data.value'>
          Link
        </b-link>
      </template>
      <template #cell(special_processing)='row'>
        <div class='text-center'>
          <b-form-checkbox
            v-model='row.item.special_processing' switch></b-form-checkbox>
        </div>
      </template>
      <template #cell(notes)='row'>
        <b-button size='sm' @click='row.toggleDetails' class='mr-2'>
          {{ row.detailsShowing ? 'Hide' : 'Show' }} Notes
        </b-button>
      </template>
      <template #row-details='row'>
        <b-row cols='12' class='ml-1'>
          <b-col cols='5'></b-col>
          <b-col cols='1' style='text-align: right; vertical-align: middle; '>
            <label>Notes:</label>
          </b-col>
          <b-col cols='6'>
            <b-form-input v-model='row.item.notes' placeholder='Enter your note'></b-form-input>
          </b-col>
        </b-row>
      </template>
    </b-table>
  </div>
</template>

<script>

export default {
  name: 'ProductionFileTracking',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      table_settings: {
        booleans: [ 'failed_ingestion', 'has_errors', 'overdue' ],
        links: [ 'provider_link', 'config_link' ],
      },
      fields: [
        {
          key: 'onboarding_client_id',
          label: 'Client ID',
          sortable: true,
        },
        {
          key: 'onboarding_client_name',
          label: 'Client Name',
          sortable: true,
        },
        {
          key: 'provider_name',
          label: 'Provider Name',
          class: 'mid-cell',
          sortable: true,
        },
        {
          key: 'folder',
          label: 'Filepath',
          sortable: true,
        },
        {
          key: 'last_file_available',
          label: 'Last Available File',
          class: 'mid-cell',
          sortable: true,
        },
        {
          key: 'last_file_ingested',
          label: 'Last Ingested File',
          class: 'mid-cell',
          sortable: true,
        },
        {
          key: 'number_of_lines_ingested',
          label: 'Ingested Lines',
          sortable: true,
        },
        {
          key: 'revision',
          label: 'Revision',
          sortable: true,
        },
        {
          key: 'failed_ingestion',
          label: 'Failed Ingestion',
          sortable: true,
        },
        {
          key: 'has_errors',
          label: 'Ingestion With Errors',
          sortable: true,
        },
        {
          key: 'days_from_last_ingestion',
          label: 'Days Since Last Successful Ingestion',
          sortable: true,
        },
        {
          key: 'expected_cadence',
          label: 'Cadence (Days)',
          sortable: true,
        },
        {
          key: 'overdue',
          label: 'Overdue',
          sortable: true,
        },
        {
          key: 'notes',
          label: 'Notes',
          class: 'mid-cell',
        },
        {
          key: 'special_processing',
          label: 'Special Processing',
          sortable: true,
        },
        {
          key: 'provider_link',
          label: 'SFTP Provider',
        },
        {
          key: 'config_link',
          label: 'Onboarding Client',
        },
        {
          key: 'bigquery_client_id',
          label: 'Bigquery Client ID',
          sortable: true,
        },
      ],
    };
  },
  beforeMount() {
    this.data.forEach(item => {
      item.provider_link = `/sftp/providers/${item.provider_id}`;
      item.config_link = `/onboarding/clients/${item.onboarding_client_id}/eligibility`;
      item.special_processing = !!item.special_processing;
    });
  },
};
</script>

<style>
#eligibility-tracking-tbl td.mid-cell {
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
}

.b-table thead th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#eligibility-tracking-tbl th.mid-cell {
  vertical-align: middle;
}
</style>
