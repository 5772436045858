<template>
  <div class='mt-4 mr-4 ml-4'>
    <b-row class='mb-4 col-12'>
      <h4>Eligibility File Tracking (Per Onboarding Client)</h4>
    </b-row>
    <b-row cols='12' class='mb-4'>
      <b-col cols='2'>
        <b-select v-model='search.filter'>
          <b-select-option default value=''>Select an option</b-select-option>
          <b-select-option v-for='(name, value) in filters' :value='value' v-bind:key='value'>{{ name }}
          </b-select-option>
        </b-select>
      </b-col>
      <b-col cols='4'>
        <b-form-input
          id='filter-input'
          v-model='search.value'
          type='search'
          @keyup.enter='searchEvent'
          :placeholder='searchPlaceholder'>
        </b-form-input>
      </b-col>
      <b-col cols='2'>
        <b-button
          variant='outline-primary'
          @click.prevent='searchEvent'
          :disabled='loading'>Search
        </b-button>
        <b-button
          class='ml-2'
          variant='outline-primary'
          @click.prevent='resetFilters'
          :disabled='loading'>Reset Filters
        </b-button>
      </b-col>
      <b-col cols='4' class="text-right">
        <b-button
          variant='outline-primary'
          @click.prevent='saveEvent'
          :disabled='loading'>Save
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if='loading'>
      <b-col cols='12'>
        <b-spinner></b-spinner>
      </b-col>
    </b-row>
    <div class='row mt-3' v-else>
      <div class='col-12'>
        <ProductionFileTracking :data='onboardingClientsData'></ProductionFileTracking>
      </div>
    </div>
  </div>
</template>

<script>

import ProductionFileTracking from '@/components/SFTP/FileTracking/FileTracking.vue';
import * as R from 'ramda';

export const isNullOrEmpty = R.either(R.isNil, R.isEmpty);
export default {
  name: 'FileTracking',
  components: {
    ProductionFileTracking,
  },
  data() {
    return {
      loading: true,
      saving: false,
      search: {
        filter: '',
        value: null,
      },
      filters: {
        client_id: 'Onboarding Client ID',
        client_name: 'Onboarding Client Name',
        folder: 'Eligibility Folder',
      },
      onboardingClientsData: [],
    };
  },
  computed: {
    searchPlaceholder() {
      if (isNullOrEmpty(this.search.filter)) {
        return 'Select a filter first';
      }

      return `Enter the ${this.filters[this.search.filter]}`;
    },
  },
  beforeMount() {
    return this.fetchClients();
  },
  methods: {
    searchEvent() {
      if (isNullOrEmpty(this.search.filter) || isNullOrEmpty(this.search.value)) {
        this.$noty.error('Please choose a filter and enter the value before searching');
        return;
      }

      this.fetchClients({ [this.search.filter]: this.search.value.trim() });
    },
    resetFilters() {
      this.search = {
        filter: '',
        value: null,
      };

      this.fetchClients();
    },
    async fetchClients(params = {}) {
      this.loading = true;
      this.onboardingClientsData = [];

      try {
        const { data } = await this.$store.dispatch('Sftp/Eligibility/getEligibilityFileTracking', params);
        this.onboardingClientsData = data;
      } catch (err) {
        this.$noty.error(R.pathOr(err.message, [ 'response', 'data', 'message' ], err));
      } finally {
        this.loading = false;
      }
    },
    async saveEvent() {
      this.saving = true;

      try {
        const data = this.onboardingClientsData.map(item => ({
          onboarding_client_id: item.onboarding_client_id,
          filepath: item.folder,
          notes: item.notes,
          special_processing: item.special_processing,
          uuid: item.uuid,
        }));

        await this.$store.dispatch('Sftp/Eligibility/saveEligibilityFileTracking', data);
        if (isNullOrEmpty(this.search.filter) || isNullOrEmpty(this.search.value)) {
          await this.fetchClients();
        } else await this.fetchClients({ [this.search.filter]: this.search.value.trim() });

        this.$noty.success('Saved successfully');
      } catch (err) {
        this.$noty.error(R.pathOr(err.message, [ 'response', 'data', 'message' ], err));
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
